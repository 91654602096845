<template>
	<ej-page>
		<ej-mobile-header>
			<ej-toolbar>
				<ej-buttons>
					<ej-back-button></ej-back-button>
				</ej-buttons>

				<ej-title>{{ $route.meta.title }}</ej-title>
			</ej-toolbar>
		</ej-mobile-header>

		<ej-content>
			<ej-breadcrumb>
				<li class="breadcrumb-item">
					<router-link :to="{name: 'products'}">Produtos</router-link>
				</li>
				<li class="breadcrumb-item">
					{{ $route.meta.title }}
				</li>
			</ej-breadcrumb>

			<main class="content">
				<div class="d-flex justify-content-center" v-if="spinner">
					<div class="default-spinner">
						<div class="default-spinner-inner">
							<div></div>
							<div></div>
						</div>
					</div>
				</div>
				<div v-else>
					<div class="container-fluid">
						<div class="row">
							<div class="col">
								<h5>{{ `${product.code} - ${product.name}` }}</h5>
							</div>
						</div>

						<div class="row">
							<div class="col">
								<table class="desktop-list-content">
									<thead>
										<tr>
											<th>Código</th>
											<th>Produto/Material</th>
											<th>Un</th>
											<th>Qtd.</th>
											<th>Custo Unit.</th>
											<th>Total</th>
											<th>Perc.</th>
											<th>Últ. Atual.</th>
										</tr>
									</thead>
									<tbody>
										<tr :key="index" v-for="(product, index) in products">
											<td>{{ product.REFERENCIA }}</td>
											<td>{{ product.DESCRPRODUTO }}</td>
											<td>{{ product.UN }}</td>
											<td>{{ product.QTD }}</td>
											<td>{{ toCurrency(product.CUSTOCOTACAO,"BRL",4) }}</td>
											<td>{{ toCurrency(product.CUSTOCOTACAOTOTAL,"BRL",4) }}</td>
											<td>{{ product.PERCENTUAL }}</td>
											<td>{{ product.DTULTATUALIZACAO }}</td>
										</tr>
									</tbody>
								</table>
							</div>
						</div>

						<div class="mobile-list-content">
							<div class="row">
								<div class="col-12 col-md-6" :key="index" v-for="(product, index) in products">
									<div class="card">
										<div class="card-body">
											<h5 class="card-title"><strong>{{ product.REFERENCIA }}</strong></h5>
											<p class="card-text"><strong>Produto/Material:</strong> {{ product.DESCRPRODUTO }}</p>
											<p class="card-text"><strong>Un.:</strong> {{ product.UN }}</p>
											<p class="card-text"><strong>Qtd.:</strong> {{ product.QTD }}</p>
											<p class="card-text"><strong>Custo Unit.:</strong> {{ toCurrency(product.CUSTOCOTACAO,"BRL",4) }}</p>
											<p class="card-text"><strong>Total:</strong> {{ toCurrency(product.CUSTOCOTACAOTOTAL,"BRL",4) }}</p>
											<p class="card-text"><strong>Perc.:</strong> {{ product.PERCENTUAL }}</p>
											<p class="card-text"><strong>Últ. Atual.:</strong> {{ product.DTULTATUALIZACAO }}</p>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</main>
		</ej-content>
	</ej-page>
</template>

<script>
	//Aux
	import {exibeErro} from "@/helpers";

	//Mixins
	import CurrencyMixin from "@/mixins/CurrencyMixin";

	export default {
		name: 'ProductComposition',

		mixins: [
			CurrencyMixin
		],

		data() {
			return {
				loadingProduct: false,
				loadingProducts: false,

				product: {},
				products: [],
			}
		},

		computed: {
			spinner() {
				return this.loadingProduct || this.loadingProducts;
			}
		},

		mounted() {
			const vm = this;

			vm.loadingProduct = true;

			vm.$store.dispatch(`products/get`, vm.$route.params.id)
				.then(async function (response) {
					vm.product = Object.assign(vm.product, response.data);

					vm.loadingProducts = true;

					const composition = await vm.$store.dispatch(`products/composition`, vm.$route.params.id)
					vm.products = Object.assign(vm.products, composition.data);

					vm.loadingProducts = false;
				})
				.catch(function (error) {
					// handle error
					exibeErro(error);
				})
				.then(function () {
					// always executed
					vm.loadingProduct = false;
				});
		}
	}
</script>

<style scoped>
	/* Large devices (desktops, 992px and up) */
	@media (min-width: 992px) {
		.content {
			padding: 10px 20px 20px 20px !important;
		}
	}
</style>
