export default {
	methods: {
		toCurrency(value, currency = "BRL", precision = 2) {
			return Number
				.parseFloat(value)
				.toLocaleString('pt-BR', {
					currency: currency,
					style:"currency",
					minimumFractionDigits: precision
				});
		},
	}
}
